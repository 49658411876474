.scrollableDropdown {
  max-height: 200px;
  overflow-y: auto;
}

.appName {
  font-size: 30;
  font-weight: bold;
}

.dropdown {
  display: inline-flex;
  align-items: center;
  font-size: 21px;
}

/* Spinner */
.loadingSpinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.spinnerText {
  font-weight: bold;
  color: #17a2b8;
  padding-top: 7px;
}
