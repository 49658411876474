.formContainer {
  margin-top: 20px;
  background-color: #f6f6f6;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.formContainer:hover {
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2), 0 0px 5px rgba(0, 0, 0, 0.2);
}

.formContainerError {
  border: 2px solid red;
}

.errorMessage {
  color: red;
  margin-bottom: 20px;
  text-align: center;
  font-style: italic;
  font-size: 20px;
}

.row {
  margin-bottom: 10px;
}

.RoSNote {
  text-align: center;
  font-weight: bold;
}

.alert-link {
  text-decoration: underline !important;
}

/* Modal content */
.custom-modal .modal-dialog {
  max-width: 90%; /* Adjust this percentage as needed */
}

.modal-body .alert div {
  margin-bottom: 5px;
}
