.mainBody {
  margin: 40px;
}
.mainHeader {
  text-align: center;
  text-transform: uppercase;
  padding: 5px;
  font-weight: bold;
  font-size: 25px;
}
.clickable {
  cursor: pointer;
  color: #323b7a;
  font-weight: bold;
  font-size: 20px;
}

.mainCard {
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}
.mainCard:hover {
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1);
}

.stackStatus {
  align-content: "flex-end";
  font-weight: bold;
}

.rule {
  margin-bottom: 10px;
}

.noRule {
  font-size: 20px;
  font-style: italic;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.flagDescription{
  font-size: 16px;
  font-style: italic;
  text-align: center;
}
